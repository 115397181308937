<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        {{ error }}

        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-card>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="title"
                      rules="required"
                    >
                      <v-text-field
                        outlined
                        v-model="title"
                        label="Media Site Name"
                        type="text"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="domain"
                      rules="required"
                      ref="domain"
                    >
                      <v-text-field
                        outlined
                        v-model="domain"
                        label="Media Site Domain"
                        type="text"
                        :error-messages="errors"
                        hint="The domain must have an https:// format."
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="type"
                      rules="required"
                    >
                      <v-select
                        outlined
                        v-model="type"
                        :items="mediaChannelTypes"
                        label="Media Type"
                        :error-messages="errors"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="isActive"
                      rules="required"
                    >
                      <v-select
                        outlined
                        v-model="isActive"
                        :items="isActiveOptions"
                        label="Live"
                        :error-messages="errors"
                      ></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>
    </div>
  </v-card-text>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";

extend("required", {
  ...required,
  message: "Please provide a value.",
});

setInteractionMode("passive");

export default {
  name: "MediaChannelsForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      invalidDomain: true,
      isLoading: true,
      isSubmitting: false,
      snackbar: false,
      screenerId: this.$route.params.screenerId,
    };
  },
  computed: {
    ...mapFields("mediaChannels", {
      domain: "mediaChannel.domain",
      screenerName: "mediaChannel.screenerName",
      isActive: "mediaChannel.isActive",
      sponsorId: "mediaChannel.sponsorId",
      sponsorName: "mediaChannel.sponsorName",
      title: "mediaChannel.title",
      type: "mediaChannel.type",
    }),
    ...mapState("mediaChannels", [
      "error",
      "isActiveOptions",
      "mediaChannel",
      "mediaChannelTypes",
    ]),
    isNew() {
      return this.id === "";
    },
  },
  methods: {
    ...mapActions("mediaChannels", [
      "addMediaChannel",
      "clearMediaChannel",
      "clearMediaChannels",
      "editMediaChannel",
      "getMediaChannel",
      "getMediaChannelTypes",
    ]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setScreenerBreadcrumb",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    submit: async function () {
      if (this.isSubmitting) return;
      let success = false;
      this.isSubmitting = true;
      this.invalidDomain = true;
      if (this.mediaChannel.domain.search("https://") !== -1) {
        this.invalidDomain = false;
        if (this.isNew) {
          success = await this.addMediaChannel(this.screenerId);
        } else {
          success = await this.editMediaChannel(this.id);
        }
      }
      if (success) {
        await this.$router.push({
          name: "MediaChannelView",
          params: {
            id: this.mediaChannel.id,
          },
        });
      } else if (this.invalidDomain) {
        this.$refs.domain.applyResult({
          errors: ["The domain must have an https:// format."], // array of string errors
          valid: false, // boolean state
          failedRules: {}, // should be empty since this is a manual error.
        });
      } else {
        this.snackbar = !!this.error;
      }

      this.isSubmitting = false;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    let actionButtons = [];
    this.getMediaChannelTypes();
    if (this.screenerId === undefined) {
      this.screenerId = this.mediaChannel.screenerId;
    }
    if (this.isNew) {
      // Clear the media channel for a new media channel.
      this.clearMediaChannel();
      this.setTitleObject("Media Channel");
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/screeners/view/" + this.screenerId,
        },
        index: 0,
      });

      await this.setScreenerBreadcrumb(this.screenerId);
    } else {
      await this.getMediaChannel(this.id);
      this.setTitleObject(this.title);
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/media-channels/view/" + this.id,
        },
        index: 0,
      });

      await this.setScreenerBreadcrumb({
        id: this.mediaChannel.screenerId,
        name: this.screenerName,
      });
      this.setSponsorBreadcrumb({
        id: this.sponsorId,
        name: this.sponsorName,
      });
    }
    this.setBreadcrumbs();
    this.isLoading = false;
    actionButtons.push({
      component: "SaveButton",
      attributes: {
        onSubmit: () => this.submit(),
      },
      index: 1,
    });
    this.setActionButtons(actionButtons);
  },
  destroyed() {
    this.clearMediaChannel();
  },
};
</script>

<style scoped>
fieldset {
  border: 0;
  display: inline-flex;
}
</style>
